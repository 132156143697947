/* eslint-disable no-use-before-define */
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';

export default function MusicEducationVideoNavBtn({ to, disabled, direction, label }) {
  const Icon = direction === 'prev' ? NavigateBeforeIcon : NavigateNextIcon;
  const color = disabled ? 'disabled' : 'primary';
  const opacity = disabled ? 0.5 : 1;

  return (
    <Link to={to} style={{ textDecoration: 'none', pointerEvents: disabled ? 'none' : 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', opacity }}>
        {direction === 'prev' && <Icon color={color} fontSize="medium" />}
        <Typography variant="h5" component="span" sx={styles.prevAndNextButtons} color={color}>
          {label}
        </Typography>
        {direction === 'next' && <Icon color={color} fontSize="medium" />}
      </Box>
    </Link>
  );
}

MusicEducationVideoNavBtn.propTypes = {
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

const styles = {
  prevAndNextButtons: {
    overflow: 'hidden',
    color: '#006DB0',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%'
  }
};
