/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Page } from '@rocksteady-music-school/rms-ui';
import { CircularProgress, Box, Typography } from '@mui/material';
import MusicEducationVideoAPI from '../api/MusicEducationVideoAPI';
import ErrorPage from './ErrorPage';
import ResourceHubTags from '../components/ResourceHub/ResourceHubTags';
import ResourceHubVideoList from '../components/ResourceHub/ResourceHubVideoList';

const TAG_NAMES = ['key_stage', 'curriculum', 'time', 'format', 'theme_collection'];

export default function ResourceHubPage() {
  const [searchParams] = useSearchParams();

  // Get the tag search query from the URL
  const tagSearchQuery = searchParams.get('tag');

  // Decode the tag search query
  const decodedTagSearchQuery = tagSearchQuery ? decodeURIComponent(tagSearchQuery) : '';

  // The first part of the tag search query is the tag name and the second part is the tag element, they are separated by a hyphen
  const tagSearchQueryParts = decodedTagSearchQuery ? decodedTagSearchQuery.split('-') : [];
  const tagName = tagSearchQueryParts[0];
  const tagElement = tagSearchQueryParts[1];

  const [videos, setVideos] = useState([]);
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [state, setState] = useState('loading');

  useEffect(() => {
    MusicEducationVideoAPI.getVideos()
      .then((response) => {
        const videosFromResponse = response.data.data.map((video) => video.attributes);
        setVideos(videosFromResponse);
        setFilteredVideos(videosFromResponse);

        setState('loaded');
      })
      .catch(() => {
        setState('failed');
      });
  }, []);

  useEffect(() => {
    // Filter the videos based on the tag search query
    if (videos.length && tagName && tagElement) {
      const taggedVideos = videos.filter((video) => {
        if (tagName === 'theme_collection') {
          // return the videos that have the selected series name
          return video.music_education_video_series === tagElement;
        }
        if (!video[`${tagName}_tags`]) return false;
        return video[`${tagName}_tags`].includes(tagElement);
      });

      setFilteredVideos(taggedVideos);
    }
  }, [videos, tagSearchQuery, state]);

  const tagsContainer = () => <ResourceHubTags videos={videos} />;

  const showDefaultHeading = () => !tagElement || !TAG_NAMES.includes(tagName);

  const topContainer = () => (
    <Box sx={{ width: '100%' }}>
      <Box style={styles.heading}>
        <Typography style={styles.text1}>
          {showDefaultHeading() ? 'Rocksteady Schools' : tagElement}
        </Typography>
        {showDefaultHeading() && <Typography style={styles.text2}>Video Resource Hub</Typography>}
      </Box>
      <Typography style={styles.text3}>
        Lorem ipsum odor amet, consectetuer adipiscing elit. Congue mollis sit netus, class
        porttitor parturient potenti varius. Eget lobortis eu aliquet facilisis bibendum magnis
        auctor natoque phasellus. Torquent elementum maecenas magnis malesuada cursus felis. Litora
        molestie purus ex consequat vivamus. Arcu dictum at nibh magnis risus ullamcorper lacus
        natoque.
      </Typography>
    </Box>
  );

  if (state === 'loading') {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
        <CircularProgress sx={{ color: 'white' }} data-testid="circular-progress" />;
      </Box>
    );
  }

  if (state === 'failed') {
    return <ErrorPage content="pageNotFound" />;
  }
  if (state === 'loaded') {
    return (
      <Page>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {topContainer()}
          <ResourceHubVideoList videos={filteredVideos} />
          {tagsContainer()}
        </Box>
      </Page>
    );
  }
}

const styles = {
  heading: {
    display: 'flex',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    gap: '20px',
    alignSelf: 'stretch',
    flexWrap: 'wrap'
  },
  text1: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '55px',
    fontStyle: 'italic',
    fontWeight: '800',
    lineHeight: '120%',
    textTransform: 'uppercase',
    background: 'linear-gradient(to bottom right, #69C3C6 0%, #006DB0 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  text2: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    flex: '1 0 0',
    overflow: 'hidden',
    color: '#004283',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '45px',
    fontStyle: 'italic',
    fontWeight: '800',
    lineHeight: '120%',
    textTransform: 'uppercase'
  },
  text3: {
    overflow: 'hidden',
    color: '#004283',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '25px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '120%',
    paddingTop: 30
  }
};
