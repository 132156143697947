/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Pagination, Box, Typography, Grid } from '@mui/material';
import MusicEducationVideo from './MusicEducationVideo';
import paths from '../../consts/paths';

const NO_OF_VIDEOS_PER_PAGE = 8;

export default function ResourceHubVideoList({ videos, mainVideo }) {
  const [activePage, setActivePage] = useState(1);
  const [videosToShowOnPage, setVideosToShowOnPage] = useState([]);

  useEffect(() => {
    setVideosToShowOnPage(videos.slice(0, NO_OF_VIDEOS_PER_PAGE));
  }, [videos]);

  const numberOfPages = Math.ceil(videos.length / NO_OF_VIDEOS_PER_PAGE);

  const handlePageChange = (_event, value) => {
    setActivePage(value);
    const startIndex = (value - 1) * NO_OF_VIDEOS_PER_PAGE;
    const endIndex = value * NO_OF_VIDEOS_PER_PAGE;

    setVideosToShowOnPage(videos.slice(startIndex, endIndex));
    // Scroll to the top of the video list
    document.getElementById('video-list').scrollIntoView({ behavior: 'smooth' });
  };

  const videoListPagination = () => (
    <Box sx={{ width: '100%', mt: 40, display: 'flex', justifyContent: 'center' }}>
      <Pagination
        count={numberOfPages}
        page={activePage}
        onChange={handlePageChange}
        shape="rounded"
        sx={{
          '& .MuiPaginationItem-page': {
            color: '#252B5A',
            fontFamily: 'Barlow',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '700'
          },
          '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: '#252B5A',
            color: 'white',
            borderRadius: 0
          },
          '& .MuiPaginationItem-icon': {
            fontSize: '2rem'
          }
        }}
      />
    </Box>
  );

  const videoListContainer = () => (
    <Box sx={{ width: '100%', my: 30 }}>
      <Typography mb={30} style={styles.subHeading} component="p" id="video-list">
        Eget lobortis eu aliquet facilisis bibendum magnis auctor natoque phasellus
      </Typography>
      <Grid container spacing={40} px={10}>
        {videosToShowOnPage.map((video, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={video.slug}
            sx={{
              // opacity: logic based on authentication and whether we have the teaser or full video uid ? 0.3 : 1,
              cursor: 'pointer'
            }}>
            <Link to={paths.RESOURCE_HUB_VIDEO.replace(':slug', video.slug)}>
              <MusicEducationVideo
                isSelected={mainVideo && mainVideo.slug === video.slug}
                video={video}
                small
                disabled={index === videosToShowOnPage.length - 1}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
      {videoListPagination()}
    </Box>
  );

  return videoListContainer();
}

const styles = {
  subHeading: {
    overflow: 'hidden',
    color: '#006DB0',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%'
  }
};
