/* eslint-disable no-use-before-define */
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import inSchoolLessons from '../../assets/images/in_school_lessons.png';
import paths from '../../consts/paths';

export default function InSchoolLessonsBanner() {
  const navigate = useNavigate();

  const imageContainer = () => (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      alignSelf={{ xs: 'center' }}
      width={{ xs: 'auto', md: '50%' }}>
      <img src={inSchoolLessons} alt="child playing an instrument" style={{ width: '100%' }} />
    </Box>
  );

  const heading = () => (
    <Typography style={styles.adHeading}>Rocksteady In-School Band Lessons</Typography>
  );

  const description = () => (
    <Typography component="p" style={styles.adDescription}>
      Rocksteady deliver peripatetic rock and pop band lessons in primary schools using a unique and
      proven child-led pedagogy, which enables any child in your school to learn to play an
      instrument in a band with their peers.
    </Typography>
  );

  const ctaBtn = () => (
    <Button
      className="rebrand-button"
      type="submit"
      sx={styles.adBtn}
      onClick={() => navigate(paths.BAND_LESSONS)}>
      More about In-School Lessons
    </Button>
  );

  const content = () => (
    <Box
      sx={{
        ...styles.ad,
        flexDirection: { xs: 'column', md: 'row' },
        marginX: { xs: 0, sm: 150, md: 0, lg: 0 }
      }}>
      {imageContainer()}
      <Box
        sx={{
          width: { xs: 'auto', md: '100%' },
          ...styles.adContent
        }}>
        {heading()}
        {description()}
        {ctaBtn()}
      </Box>
    </Box>
  );

  return content();
}

const styles = {
  ad: {
    display: 'flex',
    maxWidth: '900px',
    padding: '0px 20px 10px 20px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '30px',
    alignSelf: 'stretch'
  },
  adContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 0',
    gap: 20
  },
  adHeading: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    alignSelf: 'stretch',
    overflow: 'hidden',
    color: '#006DB0',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '30px',
    fontStyle: 'italic',
    fontWeight: '800',
    lineHeight: '112.5%',
    textTransform: 'uppercase'
  },
  adDescription: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    alignSelf: 'stretch',
    overflow: 'hidden',
    color: '#2A2B33',
    textOverflow: 'ellipsis',
    fontFamily: 'Barlow',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '150%'
  },
  adBtn: {
    marginY: 20,
    backgroundColor: '#FFEF77 !important',
    color: '#252B5A !important',
    fontSize: '20px !important'
  }
};
