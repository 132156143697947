/* eslint-disable no-use-before-define */
import { Container, Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from '@rocksteady-music-school/rms-ui';
import UserAPI from '../api/UserAPI';
import { useAuth } from '../contexts/AuthContext';
import { useUserData } from '../contexts/UserContext';
import CookieConsentModal from '../components/CookieConsentModal/CookieConsentModal';
import { ResourceHubSiteHeader } from '../components/ResourceHub/ResourceHubSiteHeader';
import hubBackground from '../assets/images/hub_background.png';

export default function LayoutHub() {
  const { isAuthenticated } = useAuth();
  const { setUserData } = useUserData();

  const [loading, setLoading] = useState();

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);

      UserAPI.fetch()
        .then((res) => {
          setUserData(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          // If it's not a 401, throw the error so that we get the general error message on screen
          // we don't want the user to have access to any part of the site if we don't know who the user is
          if (err.request.status !== 401) {
            setUserData(() => {
              throw new Error(err);
            });
          }
        });
    } else {
      setUserData(null);
    }
  }, [isAuthenticated]);

  const loadingIndicator = () => (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
      <CircularProgress sx={{ color: 'white' }} />
    </Box>
  );

  const mainContent = () => {
    if (loading) return loadingIndicator();

    return <Outlet />;
  };

  const pageHeaderSchoolsResources = () => <ResourceHubSiteHeader />;

  return (
    <Container sx={{ ...styles.mainContainer, px: { xs: 0 } }}>
      <Box sx={styles.contentContainer}>
        <Box sx={styles.innerContainer} px={{ xs: 20, sm: 20, md: 30, lg: 0 }}>
          {/* HEADER */}
          {pageHeaderSchoolsResources()}

          {/* MAIN PAGE */}
          <Box className="video-resource-hub" flexGrow={1}>
            {mainContent()}
          </Box>

          {/* FOOTER */}
          <Box mx={30}>
            <Footer isAuthenticated={isAuthenticated} />
          </Box>
        </Box>

        {/* MODALS */}
        <CookieConsentModal />
      </Box>
    </Container>
  );
}

const styles = {
  mainContainer: {
    minHeight: '100vh',
    minWidth: '100vw',
    margin: 0,
    padding: 0,
    backgroundImage: `url(${hubBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  contentContainer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: 1200
  }
};
