/* eslint-disable no-use-before-define */
import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../contexts/AuthContext';

import playIcon from '../../assets/images/play-icon.svg';
import CookieUtils from '../../utils/CookieUtils';

export default function MusicEducationVideo({ video, small, disabled, isSelected }) {
  const { title, description } = video;
  const { isAuthenticated } = useAuth();

  const vimeoId = isAuthenticated ? video.vimeo_id : video.teaser_vimeo_id;

  const { enqueueSnackbar } = useSnackbar();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoHasError, setVideoHasError] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    // Only show the error message if the video has an error and it's not one of the small preview videos
    if (videoHasError && !small) {
      enqueueSnackbar(
        'There was an error with loading the video on this page. Please try refreshing the page and ensure your IT department allows Vimeo content.',
        { variant: 'error', autoHideDuration: 8000 }
      );
    }
  }, [videoHasError]);

  useEffect(() => {
    return () => {
      // Cleanup function to cancel state updates if the component is unmounted
      setPlayer(null);
    };
  }, []);

  const displayPlayButton = () => {
    if (isMobile) return false;
    if (!player) return false;
    if (videoPlaying) return false;
    return true;
  };

  const playButton = () => (
    <Box
      onClick={() => (player ? player.play() : null)}
      className="playButton"
      component="img"
      alt="playIcon"
      src={playIcon}
      sx={{ ...styles.playIcon, display: displayPlayButton() ? 'inline-block' : 'none' }}
    />
  );

  const vimeoVideo = () => (
    <Vimeo
      className="MusicEducationVideo"
      video={vimeoId}
      key={vimeoId}
      playsInline={false}
      responsive
      onReady={(playerObj) => setPlayer(playerObj)}
      onPlay={() => setVideoPlaying(true)}
      onEnd={() => setVideoPlaying(false)}
      onLoaded={() => setVideoLoaded(true)}
      onError={() => setVideoHasError(true)}
      dnt={!CookieUtils.analyticsConsentGiven()}
      style={{
        display: videoLoaded ? 'inherit' : 'none'
      }}
    />
  );

  const videoContainer = () => {
    return (
      <>
        {videoLoaded ? null : (
          <Box sx={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
            {/* By using paddingTop of 56.25% above we're maintaining a 16:9 aspect ratio, which matches the vimeo video */}
            <Skeleton
              variant="rectangular"
              sx={styles.videoSkeleton}
              data-testid="video-skeleton"
            />
          </Box>
        )}

        <Box sx={{ position: 'relative' }}>
          {vimeoVideo()}
          {isSelected ? (
            <Box sx={styles.isSelectedBanner}>
              <Typography variant="p" style={styles.isSelectedText}>
                Selected Episode
              </Typography>
            </Box>
          ) : null}
          {!disabled && !isSelected && playButton()}
        </Box>
        {/* TODO: when this is a small video, don't load the vimeo video, only show a container with the thumbnail */}
        {small ? (
          <Box sx={{ width: '100%', my: 10 }}>
            <Typography variant="h5" style={styles.smallTitle}>
              {title}
            </Typography>
            <Typography variant="body" style={styles.smallDescription}>
              {description}
            </Typography>
          </Box>
        ) : null}
      </>
    );
  };

  return videoContainer();
}

const styles = {
  playIcon: {
    position: 'absolute',
    maxHeight: '33%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: '0.7',
    transitionProperty: 'opacity',
    transitionDuration: '0.3s',
    '&:hover': {
      cursor: 'pointer',
      opacity: '1',
      transitionProperty: 'opacity',
      transitionDuration: '0.3s'
    }
  },
  smallTitle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    alignSelf: 'stretch',
    overflow: 'hidden',
    color: '#004283',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '22px',
    fontStyle: 'italic',
    fontWeight: '700',
    lineHeight: '120%'
  },
  smallDescription: {
    // Show only two lines:
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    color: '#2A2B33',
    textOverflow: 'ellipsis',
    fontFamily: 'Barlow',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '140%'
  },
  isSelectedBanner: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '25%',
    backgroundColor: '#252B5A',
    display: 'flex',
    alignItems: 'center'
  },
  isSelectedText: {
    padding: '0 10px',
    overflow: 'hidden',
    color: '#FFF',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '16px',
    fontStyle: 'italic',
    fontWeight: '700',
    lineHeight: '120%',
    letterSpacing: '-0.32px',
    textTransform: 'uppercase'
  },
  videoSkeleton: { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }
};
