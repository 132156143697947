/* eslint-disable no-use-before-define */
import { Typography, Box, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import paths from '../../consts/paths';

export default function ResourceHubCTA() {
  const navigate = useNavigate();

  const createAccountBtn = () => (
    <Button
      className="rebrand-button"
      type="submit"
      sx={styles.createAccountBtn}
      onClick={() => navigate(paths.SIGN_UP)}>
      Create your free account
    </Button>
  );

  const ctaBanner = () => (
    <Box sx={styles.main} px={{ xs: 20, md: 0 }}>
      <Box sx={styles.polygon}>
        <Typography variant="body" color="white" component="p" sx={styles.text1}>
          Sign up with your school email to watch all of Rocksteady’s quality content — for free!
        </Typography>

        {createAccountBtn()}

        <Typography variant="body" color="white" component="p" sx={styles.text2}>
          Rocksteady already at your school?{' '}
          <Link to={paths.LOG_IN}>
            <Typography variant="bodyLink" color="white">
              Sign in
            </Typography>
          </Link>
        </Typography>
      </Box>
    </Box>
  );

  return ctaBanner();
}

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch'
  },
  polygon: {
    clipPath: 'polygon(3% 8%, 96% 0, 100% 100%, 0 83%)',
    background: 'linear-gradient(98deg, #A473AF 21.3%, #004283 78.86%)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingY: 60,
    paddingX: 20
  },
  text1: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Barlow',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '140%'
  },
  text2: {
    overflow: 'hidden',
    color: '#FFF',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    fontFamily: 'Barlow',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '140%'
  },
  createAccountBtn: {
    marginY: 20,
    backgroundColor: '#FFEF77 !important',
    color: '#252B5A !important',
    fontSize: '25px !important'
  }
};
