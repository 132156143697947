import * as React from 'react';
import { useState } from 'react';
import { Routes, Route, Navigate, useSearchParams, useNavigate, useParams } from 'react-router-dom';
import { usePrismicPreviewResolver } from '@prismicio/react';
import AuthProvider from './components/AuthProvider';
import RequireAuth from './components/RequireAuth';
import AuthLayout from './routes/AuthLayout';
import Layout from './routes/Layout';
import ErrorPage from './routes/ErrorPage';
import LoginPage from './routes/LoginPage';
import ContactsPage from './routes/ContactsPage';
import FeelGoodFridayPage from './routes/FeelGoodFridayPage';
import TimetablePage from './routes/TimetablePage';
import SignUpConfirmationPage from './routes/SignUpConfirmationPage';
import SignUpPage from './routes/SignUpPage';
import paths from './consts/paths';
import UserUtils from './utils/UserUtils';
import RequestPasswordResetPage from './routes/RequestPasswordResetPage';
import SetPasswordPage from './routes/SetPasswordPage';
import AccountSettingsPage from './routes/AccountSettingsPage';
import UserProvider from './components/UserProvider';
import CookiePolicyPage from './routes/CookiePolicyPage';
import ImpactReportPage from './routes/ImpactReportPage';
import BandLessonsPage from './routes/BandLessonsPage';
import MatchFundingPage from './routes/MatchFundingPage';
import KeyResourcesPage from './routes/KeyResourcesPage';
import CampaignPage from './routes/CampaignPage';
import UnlockAccountPage from './routes/UnlockAccountPage';
import ResourceHubVideoPage from './routes/ResourceHubVideoPage';
import LayoutHub from './routes/LayoutHub';
import ResourceHubPage from './routes/ResourceHubPage';

function ResourceHubVideoPageWrapper() {
  const { slug } = useParams();
  return <ResourceHubVideoPage key={slug} />;
}

export default function App() {
  const [userData, setUserData] = useState(null);
  const [searchParams] = useSearchParams();

  const userRole = userData?.attributes?.role;
  const isActiveSchool = userData?.attributes?.school?.status === 'active';
  const activeSchoolAdminOrStaff = isActiveSchool && UserUtils.isAdminOrStaff(userRole);
  const activeSchoolAdmin = isActiveSchool && UserUtils.isAdmin(userRole);

  const rootPage = () => (activeSchoolAdminOrStaff ? <TimetablePage /> : <FeelGoodFridayPage />);

  const urlForFGFRedirect = () => {
    // Only append the query params if there are any
    if (searchParams.size) {
      return `/?${searchParams.toString()}`;
    }
    return '/';
  };

  const feelGoodFridayElement = () => {
    if (userData) {
      return activeSchoolAdminOrStaff ? (
        <RequireAuth>
          <FeelGoodFridayPage />
        </RequireAuth>
      ) : (
        <Navigate to={urlForFGFRedirect()} replace />
      );
    }
    // If a user tries to access /feel-good-friday and they're not logged in
    // the line below will redirect them to /login and after login
    // they will be taken to /feel-good-friday or root depending on whether they're an active school or not
    return <RequireAuth>{rootPage()}</RequireAuth>;
  };

  function Preview() {
    const navigate = useNavigate();
    usePrismicPreviewResolver({ navigate });
    return null; // This component does not render anything
  }

  return (
    <AuthProvider>
      <UserProvider userData={userData} setUserData={setUserData}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="*" element={<ErrorPage content="pageNotFound" />} />
            <Route element={<AuthLayout />}>
              <Route path={paths.LOG_IN} element={<LoginPage />} />
              <Route path={paths.SIGN_UP} element={<SignUpPage />} />
              <Route path={paths.SIGN_UP_CONFIRMATION} element={<SignUpConfirmationPage />} />
              <Route path={paths.REQUEST_PASSWORD_RESET} element={<RequestPasswordResetPage />} />
              <Route path={paths.SET_PASSWORD} element={<SetPasswordPage />} />
              <Route path={paths.UNLOCK_ACCOUNT} element={<UnlockAccountPage />} />
            </Route>

            <Route path={paths.ROOT} element={<RequireAuth>{rootPage()}</RequireAuth>} />

            {/* If a non-active school user or a basic user accesses /feel-good-friday
            redirect them to the root page which is where their FGF content is hosted */}
            <Route path={paths.FEEL_GOOD_FRIDAY} element={feelGoodFridayElement()} />
            <Route
              path={paths.BAND_LESSONS}
              element={
                <RequireAuth>
                  {!isActiveSchool ? <BandLessonsPage /> : <ErrorPage content="pageNotFound" />}
                </RequireAuth>
              }
            />

            <Route
              path={paths.CONTACTS}
              element={
                <RequireAuth>
                  <ContactsPage />
                </RequireAuth>
              }
            />

            <Route
              path={paths.ACCOUNT_SETTINGS}
              element={
                <RequireAuth>
                  <AccountSettingsPage />
                </RequireAuth>
              }
            />

            <Route
              path={paths.MATCH_FUNDING}
              element={
                <RequireAuth>
                  {activeSchoolAdmin ? <MatchFundingPage /> : <ErrorPage content="pageNotFound" />}
                </RequireAuth>
              }
            />

            <Route
              path={paths.IMPACT_REPORT}
              element={
                <RequireAuth>
                  {activeSchoolAdminOrStaff ? (
                    <ImpactReportPage />
                  ) : (
                    <ErrorPage content="pageNotFound" />
                  )}
                </RequireAuth>
              }
            />

            <Route path={paths.CAMPAIGN} element={<CampaignPage />} />

            <Route path={paths.COOKIE_POLICY} element={<CookiePolicyPage />} />

            <Route
              path={paths.KEY_RESOURCES}
              element={
                <RequireAuth>
                  <KeyResourcesPage />
                </RequireAuth>
              }
            />
            {/* "/documents" was old path to what is now "/key-resources" */}
            <Route path="/documents" element={<Navigate to={paths.KEY_RESOURCES} replace />} />
          </Route>
          <Route path="/preview" element={<Preview />} />
          <Route element={<LayoutHub />}>
            <Route path={paths.RESOURCE_HUB_VIDEO} element={<ResourceHubVideoPageWrapper />} />
            <Route path={paths.RESOURCE_HUB} element={<ResourceHubPage />} />
          </Route>
        </Routes>
      </UserProvider>
    </AuthProvider>
  );
}
