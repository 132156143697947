export default class WindowUtils {
  static anyParams = () => window.location.search;

  static scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  static currentPath = () => window.location.pathname;

  static reload = () => window.location.reload();

  static replaceLocation = (path) => {
    window.location = path;
  };
}
