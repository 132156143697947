/* eslint-disable no-use-before-define */
import { Box, Typography } from '@rocksteady-music-school/rms-ui';
import { useNavigate } from 'react-router-dom';
import StringUtils from '../../utils/StringUtils';
import WindowUtils from '../../utils/WindowUtils';

export default function ResourceHubTags({ videos }) {
  const navigate = useNavigate();

  const extractSeriesNamesFromVideos = () => {
    const seriesNames = videos.map((video) => video.music_education_video_series);
    // Remove duplicates
    const uniqueSeriesNames = new Set(seriesNames);
    // Remove empty strings, nulls, and undefined
    return [...uniqueSeriesNames].filter((name) => name);
  };

  const extractKeyStageFromVideos = () => {
    const keyStages = videos.map((video) => video.key_stage_tags).flat();
    // Remove duplicates
    const uniqueKeyStages = new Set(keyStages);
    // Order them alphabetically
    return [...uniqueKeyStages].filter((keyStage) => keyStage).sort();
  };

  const extractTimeFromVideos = () => {
    const times = videos.map((video) => video.time_tags).flat();

    // Remove duplicates
    let uniqueTimes = new Set(times);
    uniqueTimes = [...uniqueTimes].filter((time) => time);

    // Convert the set to an array and sort it numerically
    return uniqueTimes.sort((a, b) => {
      const numA = parseInt(a, 10);
      const numB = parseInt(b, 10);
      return numA - numB;
    });
  };

  const extractCurriculumFromVideos = () => {
    const curriculums = videos.map((video) => video.curriculum_tags).flat();
    // Remove duplicates
    const uniqueCurricula = new Set(curriculums);
    return [...uniqueCurricula].filter((curriculum) => curriculum);
  };

  const extractFormatFromVideos = () => {
    const formats = videos.map((video) => video.format_tags).flat();
    // Remove duplicates
    const uniqueFormats = new Set(formats);
    return [...uniqueFormats].filter((format) => format);
  };

  const tagList = () => {
    const list = [
      {
        name: 'Key Stage',
        elements: extractKeyStageFromVideos()
      },
      {
        name: 'Time',
        elements: extractTimeFromVideos()
      },
      {
        name: 'Curriculum',
        elements: extractCurriculumFromVideos()
      },
      {
        name: 'Format',
        elements: extractFormatFromVideos()
      },
      {
        name: 'Theme, Collection',
        elements: extractSeriesNamesFromVideos()
      }
    ];

    // Remove the ones that have an empty array
    return list.filter((tag) => tag.elements.length);
  };

  const handleTagChange = (tag, el) => {
    const queryParam = `${StringUtils.humanizedStringToId(tag.name)}-${el}`;
    const encodedTagValue = encodeURIComponent(queryParam);

    navigate(`/resource-hub?tag=${encodedTagValue}`);
    WindowUtils.scrollToTop();
  };

  const tagNameWithElements = (tag) => (
    <Box key={tag.name} sx={styles.tagGroupContainer}>
      <Box sx={styles.tagGroupNameContainer}>
        <Typography variant="h5" component="span" sx={styles.tagGroupName}>
          {tag.name}
        </Typography>
      </Box>
      {tag.elements.map((el) => (
        <Box key={el} sx={styles.tagElementContainer} onClick={() => handleTagChange(tag, el)}>
          <Typography variant="h5" component="span" sx={styles.tagElement}>
            {el}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  const content = () => (
    <Box sx={{ width: '100%' }}>
      <Typography mb={30} style={styles.subHeading} component="p">
        Eget lobortis eu aliquet facilisis bibendum magnis auctor natoque phasellus
      </Typography>
      <Box data-testid="tags" sx={styles.mainContainer}>
        <Box sx={styles.innerContainer}>{tagList().map((tag) => tagNameWithElements(tag))}</Box>
      </Box>
    </Box>
  );

  return content();
}

const styles = {
  subHeading: {
    overflow: 'hidden',
    color: '#006DB0',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%'
  },
  mainContainer: {
    display: 'flex',
    padding: '40px 20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    backgroundColor: '#ECF7F8'
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    gap: '20px 40px',
    alignSelf: 'stretch',
    flexWrap: 'wrap'
  },
  tagGroupContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    gap: 20,
    flexWrap: 'wrap'
  },
  tagGroupNameContainer: {
    display: 'flex',
    padding: '10px 5px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px'
  },
  tagGroupName: {
    color: '#004283',
    textAlign: 'center',
    fontFamily: '"Barlow Condensed"',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%',
    letterSpacing: '-0.28px'
  },
  tagElementContainer: {
    display: 'flex',
    padding: '9px 15px 10px 15px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    background: 'linear-gradient(103deg, rgba(105, 195, 198, 0.00) 12.01%, #006DB0 87.99%), #69C3C6'
  },
  tagElement: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Barlow',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '125%'
  }
};
