/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Page } from '@rocksteady-music-school/rms-ui';
import { CircularProgress, Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import MusicEducationVideoAPI from '../api/MusicEducationVideoAPI';
import MusicEducationVideo from '../components/ResourceHub/MusicEducationVideo';
import ResourceHubCTA from '../components/ResourceHub/ResourceHubCTA';
import ErrorPage from './ErrorPage';
import paths from '../consts/paths';
import FGFDownloadButton from '../components/FeelGoodFriday/FGFDownloadButton';
import ResourceHubTags from '../components/ResourceHub/ResourceHubTags';
import InSchoolLessonsBanner from '../components/ResourceHub/InSchoolLessonsBanner';
import MusicEducationVideoNavBtn from '../components/ResourceHub/MusicEducationVideoNavBtn';
import ResourceHubVideoList from '../components/ResourceHub/ResourceHubVideoList';

export default function ResourceHubVideoPage() {
  const { slug } = useParams();

  const [videos, setVideos] = useState([]);
  const [mainVideo, setMainVideo] = useState(null);
  const [state, setState] = useState('loading');

  useEffect(() => {
    MusicEducationVideoAPI.getVideos()
      .then((response) => {
        const videosFromResponse = response.data.data.map((video) => video.attributes);
        setVideos(videosFromResponse);

        const videoFromSlug = videosFromResponse.find((video) => video.slug === slug);
        if (!videoFromSlug) {
          setState('failed');
        } else {
          setMainVideo(videoFromSlug);
          setState('loaded');
        }
      })
      .catch(() => {
        setState('failed');
      });
  }, [slug]);

  const mainVideoSeriesName = mainVideo?.music_education_video_series;

  const navHeader = () => (
    <Box sx={{ width: '100%' }}>
      {mainVideoSeriesName ? (
        <>
          <Typography sx={{ fontSize: 20 }} component="span" style={styles.navText1}>
            {mainVideoSeriesName}
          </Typography>
          <Typography sx={{ fontSize: 20, marginX: 10 }} component="span" style={styles.navSlash}>
            /
          </Typography>
          <Typography sx={{ fontSize: 20, color: '#666' }} component="span" style={styles.navText2}>
            {mainVideo.title}
          </Typography>
        </>
      ) : (
        <Typography sx={{ fontSize: 20, color: '#666' }} component="span" style={styles.navText2}>
          {mainVideo.title}
        </Typography>
      )}
    </Box>
  );

  const nextVideoSlug = () => {
    const currentIndex = videos.findIndex((video) => video.slug === mainVideo.slug);
    if (currentIndex === videos.length - 1) return videos[0].slug;

    const nextVideo = videos[currentIndex + 1];

    return nextVideo.slug;
  };

  const prevVideoSlug = () => {
    const currentIndex = videos.findIndex((video) => video.slug === mainVideo.slug);
    if (currentIndex === 0) return videos[videos.length - 1].slug;

    const prevVideo = videos[currentIndex - 1];

    return prevVideo.slug;
  };

  const prevAndNextButtons = () => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
      <MusicEducationVideoNavBtn
        to={paths.RESOURCE_HUB_VIDEO.replace(':slug', prevVideoSlug() || '')}
        disabled={!prevVideoSlug()}
        direction="prev"
        label="Prev video"
      />
      <MusicEducationVideoNavBtn
        to={paths.RESOURCE_HUB_VIDEO.replace(':slug', nextVideoSlug() || '')}
        disabled={!nextVideoSlug()}
        direction="next"
        label="Next video"
      />
    </Box>
  );

  const tagsContainer = () => <ResourceHubTags videos={videos} />;

  const mainVideoContainer = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Box sx={{ maxWidth: 900, width: '100%' }} marginY={30}>
        <MusicEducationVideo video={mainVideo} />
        {prevAndNextButtons()}
      </Box>
    </Box>
  );

  const resourceHubCTAContainer = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Box sx={{ maxWidth: 1000, width: '100%' }} marginBottom={30}>
        <ResourceHubCTA />
      </Box>
    </Box>
  );

  const topContainer = () =>
    mainVideo && (
      <Box sx={{ width: '100%' }}>
        {navHeader()}
        {mainVideoContainer()}
        {resourceHubCTAContainer()}
      </Box>
    );

  const mainVideoDetails = () => (
    <Box sx={{ width: '100%', maxWidth: 900 }}>
      <Typography variant="h1" mb={30} sx={styles.mainVideoTitle}>
        {mainVideo?.title}
      </Typography>
      <Typography variant="body" sx={styles.mainVideoDescription}>
        <ReactMarkdown>{mainVideo?.description}</ReactMarkdown>
      </Typography>
      {mainVideo?.file_url && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }} mt={50}>
          <Box height={72} width={{ xs: '100%', lg: '75%' }} maxWidth={380}>
            <FGFDownloadButton downloadPath={mainVideo?.file_url} />
          </Box>
        </Box>
      )}
    </Box>
  );

  const inSchoolLessonsBannerContainer = () => (
    <Box sx={{ width: '100%', mt: 30, display: 'flex', justifyContent: 'center' }}>
      <InSchoolLessonsBanner />
    </Box>
  );

  if (state === 'loading') {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
        <CircularProgress sx={{ color: 'white' }} data-testid="circular-progress" />;
      </Box>
    );
  }

  if (state === 'failed') {
    return <ErrorPage content="pageNotFound" />;
  }
  if (state === 'loaded') {
    return (
      <Page>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {topContainer()}
          {mainVideoDetails()}
          <ResourceHubVideoList videos={videos} mainVideo={mainVideo} />
          {tagsContainer()}
          {inSchoolLessonsBannerContainer()}
        </Box>
      </Page>
    );
  }
}

const styles = {
  mainVideoTitle: {
    overflow: 'hidden',
    color: '#006DB0',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '55px',
    fontStyle: 'italic',
    fontWeight: '800',
    lineHeight: '120%',
    letterSpacing: '-1.1px',
    textTransform: 'uppercase'
  },
  mainVideoDescription: {
    color: '#2A2B33',
    fontFamily: 'Barlow',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '150%'
  },
  navText1: {
    color: '#004283',
    textAlign: 'center',
    fontFamily: '"Barlow Condensed"',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%'
  },
  navSlash: {
    color: 'rgba(0, 66, 131, 0.25)',
    textAlign: 'center',
    fontFamily: '"Barlow Condensed"',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '120%',
    letterSpacing: '-0.28px'
  },
  navText2: {
    overflow: 'hidden',
    color: '#004283',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    fontFamily: '"Barlow Condensed"',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%'
  }
};
