/* eslint-disable no-use-before-define */
import React from 'react';
import { AppBar, Box, Toolbar, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Typography } from '@rocksteady-music-school/rms-ui';
import paths from '../../consts/paths';
import logo from '../../assets/images/logo_rebranded.svg';

const logoHeight = 64;
const logoWidth = 58;

// eslint-disable-next-line import/prefer-default-export
export function ResourceHubSiteHeader() {
  const navigate = useNavigate();

  const logoContainer = () => (
    <Link to={paths.ROOT}>
      <Box
        component="img"
        sx={{
          height: logoHeight,
          width: logoWidth
        }}
        alt="Rocksteady Logo"
        src={logo}
      />
    </Link>
  );

  const siteIdentity = () => (
    <Box sx={styles.siteIdentityContainer}>
      {logoContainer()}
      <Box sx={styles.siteIdentityTitle}>
        <Typography variant="h2" color="white" component="span" sx={styles.header}>
          Schools
        </Typography>
        <Typography variant="h3" color="white" component="span" sx={styles.subHeader}>
          Video Resource Hub
        </Typography>
      </Box>
    </Box>
  );

  const authOptions = () => (
    <Box sx={styles.authOptions}>
      <Box sx={styles.authButtons}>
        <Box sx={styles.authButtonsInner}>
          <Button className="rebrand-button" onClick={() => navigate(paths.SIGN_UP)}>
            Create account
          </Button>
        </Box>
        <Box sx={styles.authButtonsInner}>
          <Button className="rebrand-button outlined" onClick={() => navigate(paths.LOG_IN)}>
            Sign in
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box display={{ xs: 'none', md: 'flex' }}>
      <AppBar position="relative" sx={styles.appBar}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {siteIdentity()}
          <Box sx={styles.navMenu} />
          {authOptions()}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const styles = {
  header: {
    color: '#FFF',
    fontFamily: '"Barlow Condensed"',
    fontSize: '40px',
    fontStyle: 'italic',
    fontWeight: '700',
    lineHeight: '120%',
    textTransform: 'uppercase'
  },
  subHeader: {
    color: '#DAF0F1',
    fontFamily: '"Barlow Condensed"',
    fontSize: '35px',
    fontStyle: 'italic',
    fontWeight: '800',
    lineHeight: '120%',
    textTransform: 'uppercase'
  },
  navMenu: {
    display: 'flex',
    height: '38px',
    padding: '10px',
    alignItems: 'center',
    gap: '30px',
    flex: '1 0 0'
  },
  authOptions: {
    display: 'flex',
    padding: '10px 30px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '40px'
  },
  authButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '14px'
  },
  authButtonsInner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px'
  },
  siteIdentityContainer: { display: 'flex', padding: 30, alignItems: 'center', gap: 25 },
  siteIdentityTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px'
  },
  appBar: {
    boxShadow: 'none',
    background: 'linear-gradient(180deg, #69C3C6 50%, rgba(105, 195, 198, 0.00) 100%)'
  }
};
